import React, { useState, useEffect } from "react";
import "assets/styles/pages/sidebar.scss";
import "assets/styles/pages/dashboardstatus.scss";
import { Spinner } from "react-bootstrap";
import { navigate, Link } from "gatsby";
import Form from "react-bootstrap/Form";
import donatedtree from "assets/images/admin/donated-tree.svg";
import linkicon from "assets/images/admin/link.png";
//import sidebar css from react-pro-sidebar module and our custom css
import "react-pro-sidebar/dist/css/styles.css";
import "assets/styles/pages/customheader.scss";
import AdminService from "services/admin/admin.service";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "assets/styles/pages/sidebar.scss";
import "assets/styles/pages/dashboardstatus.scss";
import PlantationListDetails from "services/plantation/plantation.service";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "react-js-pagination";
import { doAdminLogout } from "./login";
//import Initiativeviewspecies from 'components/admin/initiative-view';

export default function Donorstatuspage() {
  const ds = new PlantationListDetails();
  const adminApi = new AdminService();
  const [donorList, setDonorList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mapOpen, setMapOpen] = useState(false);
  const [error, setError] = useState(false);
  const [projectValue, setProjectValue] = useState("");
  const [proValue, setProValue] = useState("");
  const [projectDataList, setProjectDataList] = useState([]);
  // const [plantationList, setPlantationList] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [statusValue, setStatusValue] = useState("");
  const [pageCount, setPageCount] = useState(10);
  const [disabledValue, setDisabledValue] = useState(false);
  const [projectList, setProjectList] = useState("");
  const [projectChangeValue, setProjectChangeValue] = useState();
  const [mappedProjectChangeValue, setmappedProjectChangeValue] = useState();

  const validationMessage = (data) => {
    if (data.response.status === 401) {
      toast.error("Authentication failed");
      setTimeout(() => {
        doAdminLogout();
        navigate("/admin/");
      }, 3000);
    } else if (data.response.status === 403) {
      toast.error("Un Authorized user");
      setTimeout(() => {
        doAdminLogout();
        navigate("/admin/");
      }, 3000);
    } else {
      toast.error(data.response.data.message);
    }
  };

  const handlePageChange = async (pageNumber) => {
    await setActivePage(pageNumber);
    let inputs = {
      pageNumber: pageNumber - 1,
      pageSize: pageCount,
      donationListFilter: statusValue === "0" ? "1" : statusValue,
      toBeFiltered: statusValue === "0" ? false : true,
    };
    setLoading(true);
    await adminApi
      .getDonationList({ inputs })
      .then((data) => {
        if (data.status === 200) {
          setDonorList(data.data);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((errorMsg) => {
        setLoading(false);
        if (errorMsg.response) {
          validationMessage(errorMsg);
        }
      });
  };

  const pageCountChange = async (e) => {
    let targetValue = e.target.value;
    setPageCount(targetValue);
    let inputs = {
      pageNumber: activePage - 1,
      pageSize: targetValue,
      donationListFilter: statusValue === "0" ? "1" : statusValue,
      toBeFiltered: statusValue === "0" ? false : true,
    };
    setLoading(true);
    await adminApi
      .getDonationList({ inputs })
      .then((data) => {
        if (data.status === 200) {
          setDonorList(data.data);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((errorMsg) => {
        setLoading(false);
        if (errorMsg.response) {
          validationMessage(errorMsg);
        }
      });
  };

  const loadProjectList = async () => {
    let inputs = {
      pageNumber: 0,
      pageSize: 10,
      name: "",
      status: "",
      toBeFiltered: false,
    };
    setLoading(true);
    await ds
      .projectList({ inputs })
      // .donorListSearch()
      .then((data) => {
        if (data.status === 200) {
          setProjectDataList(data.data.projects);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((errorMsg) => {
        setLoading(false);
        if (errorMsg.response) {
          validationMessage(errorMsg);
        }
      });
  };

  const mapProject = (data) => {
    setMapOpen(true);
    setProValue(data);
  };

  const listPlanters = async () => {
    let inputs = {
      pageNumber: 0,
      pageSize: 100,
      name: "",
      status: "",
      toBeFiltered: false,
    };
    setLoading(true);
    await adminApi
      .listProjects({ inputs })
      .then((data) => {
        if (data.status === 200) {
          setLoading(false);
          // setPlantationList(data.data);
          setProjectList(data?.data);
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((errorMsg) => {
        setLoading(false);
        if (errorMsg.response) {
          validationMessage(errorMsg);
        }
      });
  };

  const listDonors = async () => {
    let inputs = {
      pageNumber: 0,
      pageSize: pageCount,
      donationListFilter: 1,
      toBeFiltered: false,
    };
    setLoading(true);
    await adminApi
      .getDonationList({ inputs })
      .then((data) => {
        if (data.status === 200) {
          setDonorList(data.data);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((errorMsg) => {
        setLoading(false);
        if (errorMsg.response) {
          validationMessage(errorMsg);
        }
      });
  };

  const closeModalpop = () => {
    setMapOpen(false);
    setProjectValue("");
    setError(false);
    setDisabledValue(false);
  };

  const submitMap = async () => {
    let subForm = {
      donationId: proValue.donationId,
      projectId: projectValue,
    };

    if (projectValue === "") {
      setError(true);
    }

    if (projectValue !== "") {
      setLoading(true);
      await adminApi
        .getMapDonation({ subForm })
        .then((data) => {
          if (data.status === 200) {
            setLoading(false);
            toast.success("Species Mapped Successfully");
            closeModalpop();
            listDonors();
          } else {
            setLoading(false);
            toast.error("Something went wrong");
          }
        })
        .catch((errorMsg) => {
          setLoading(false);
          if (errorMsg.response) {
            validationMessage(errorMsg);
          }
        });
    }
  };
  const selctPr = (e) => {
    let optionValue = e.target.value;
    let split = optionValue.split(",");
    let id = split[0];
    let projectCount = Number(split[1]);
    let donorTrees = Number(proValue.donatedTrees);
    if (projectCount >= donorTrees) {
      setDisabledValue(false);
      setProjectValue(id);
      if (e.target.value !== "") {
        setError(false);
      }
    } else {
      setDisabledValue(true);
    }
  };

  useEffect(() => {
    setStatusValue("");
    listDonors();
    loadProjectList();
    listPlanters();
  }, []);

  const onStatusChange = async (value) => {
    setStatusValue(value);
    await setActivePage(1);
    let inputs = {
      pageNumber: 0,
      pageSize: pageCount,
      donationListFilter: value === "0" ? "1" : value,
      toBeFiltered: value === "0" ? false : true,
      ...(projectChangeValue && { donatedProjectId: projectChangeValue }),
      ...(mappedProjectChangeValue && {
        mappedProjectId: mappedProjectChangeValue,
      }),
    };

    setLoading(true);
    await adminApi
      .getDonationList({ inputs })
      .then((data) => {
        if (data.status === 200) {
          setDonorList(data.data);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((errorMsg) => {
        setLoading(false);
        if (errorMsg.response) {
          if (errorMsg.response.status === 401) {
            toast.error("JWT Token has Expired");
            navigate("/");
          } else {
            toast.error(errorMsg.response.data.message);
          }
        }
      });
  };

  // useEffect(() => {
  //   // listPlantations();
  //   listPlanters();
  // }, []);

  const onProjectChange = async (value) => {
    setProjectChangeValue(value);
    await setActivePage(1);
    let inputs = {
      pageNumber: 0,
      pageSize: pageCount,
      ...(statusValue && { donationListFilter: statusValue }),
      toBeFiltered: value === "0" ? false : true,
      donatedProjectId: value,
      ...(mappedProjectChangeValue && {
        mappedProjectId: mappedProjectChangeValue,
      }),
    };

    setLoading(true);
    await adminApi
      .getDonationList({ inputs })
      .then((data) => {
        if (data.status === 200) {
          setDonorList(data.data);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((errorMsg) => {
        setLoading(false);
        if (errorMsg.response) {
          if (errorMsg.response.status === 401) {
            toast.error("JWT Token has Expired");
            navigate("/");
          } else {
            toast.error(errorMsg.response.data.message);
          }
        }
      });
  };

  const onMappedProjectChange = async (value) => {
    setmappedProjectChangeValue(value);
    await setActivePage(1);
    let inputs = {
      pageNumber: 0,
      pageSize: pageCount,
      ...(statusValue && { donationListFilter: statusValue }),
      toBeFiltered: value === "0" ? false : true,
      ...(projectChangeValue && { donatedProjectId: projectChangeValue }),
      mappedProjectId: value,
    };

    setLoading(true);
    await adminApi
      .getDonationList({ inputs })
      .then((data) => {
        if (data.status === 200) {
          setDonorList(data.data);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((errorMsg) => {
        setLoading(false);
        if (errorMsg.response) {
          if (errorMsg.response.status === 401) {
            toast.error("JWT Token has Expired");
            navigate("/");
          } else {
            toast.error(errorMsg.response.data.message);
          }
        }
      });
  };

  return (
    <>
      <div className="dashboard-status-wrapper">
        <div className="row">
          <div className="dark-theme-green margin-btm"> DONORS </div>

          <div className="col-md-4">
            <div
              className="donor-search-dropdown margin-btm"
              style={{ visibility: "hidden" }}
            >
              Is Mapped
            </div>
            <div className="project-status-select">
              {/* <Form.Control className='projectselect search-box-input' type='text' placeholder='' /> */}
              <Form.Select
                className="all-status-input-box"
                aria-label="All status"
                onChange={(e) => {
                  onStatusChange(e.target.value);
                }}
              >
                <option value="0">All</option>
                <option value="1">Mapped</option>
                <option value="2">Not Mapped</option>
              </Form.Select>
            </div>
          </div>
          <div className="col-md-4">
            <div className="donor-search-dropdown margin-btm">
              Donated Project
            </div>

            <div className="project-status-select">
              <Form.Select
                className="projectselect all-project-input-box"
                aria-label="All Projects"
                onChange={(e) => {
                  onProjectChange(e.target.value);
                }}
              >
                <option>All Projects</option>
                {projectList.projects &&
                  projectList.projects.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </Form.Select>
            </div>
          </div>
          <div className="col-md-4">
            <div className="donor-search-dropdown margin-btm">
              Mapped Project
            </div>

            <div className="project-status-select">
              <Form.Select
                className="projectselect all-project-input-box"
                aria-label="All Projects"
                onChange={(e) => {
                  onMappedProjectChange(e.target.value);
                }}
              >
                <option>All Projects</option>
                {projectList.projects &&
                  projectList.projects.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </Form.Select>
            </div>
          </div>
        </div>
        <div className="row mg-top20">
          {donorList.donorDonationDetails &&
            donorList.donorDonationDetails.map((item) => {
              return (
                <div className="col-md-4">
                  <div className="dashboard-card-wrapper">
                    <div className="dashboard-card-body wid100 dashboard-card-body-card">
                      <div className="dis-title-status">
                        <h5 className="heading-text"> {item.donorName}</h5>
                        <span className="right-hightlight-msg reject-color">
                          {item.isSaplingMapped === true ? (
                            <span style={{ color: "green" }}>Mapped</span>
                          ) : (
                            "Not-mapped"
                          )}
                        </span>
                      </div>
                      <div className="dis-title-status">
                        <span className="small-tree-text mg-right40">
                          {" "}
                          <img
                            className="img-fluid"
                            src={donatedtree}
                            alt="bellicon"
                          />{" "}
                          Donated Trees{" "}
                        </span>
                        <span className="small-tree-text">
                          {" "}
                          {item.donatedTrees}{" "}
                        </span>
                      </div>
                      <p class="reg-id-num ellispe donor-list-padding">
                        {" "}
                        Donation Id # {item.certificateId}{" "}
                      </p>

                      <div className="donor-list-padding">
                        {/* <p className='date-time-projectName'> {item.projectName} </p> */}
                        <p className="date-time-text ellispe">
                          {" "}
                          {item.donorEmailAddress}
                        </p>
                      </div>
                      <div className="donor-list-padding">
                        <p className="date-time-text ellispe">
                          {" "}
                          <b>Donated Project</b> : {item.donatedProject.name}
                        </p>
                      </div>
                      {item?.isSaplingMapped && (
                        <div className="donor-list-padding">
                          <p className="date-time-text ellispe">
                            {" "}
                            <b>Mapped Project</b> :{" "}
                            {item.mappedProject?.[0].name}
                          </p>
                        </div>
                      )}
                      <div className="item-direction-wr1">
                        <p className="date-time-text p-wid70">
                          {" "}
                          {moment(item.donationDate).format("llll")}{" "}
                        </p>
                        <span className="sp30">
                          {item.isSaplingMapped === false ? (
                            <img
                              className={`eye-max-donor-card img-fluid  circle-view margin-left-70${
                                item.isSaplingMapped === true ? "disabled" : ""
                              }`}
                              src={linkicon}
                              alt="bellicon"
                              onClick={() => {
                                mapProject(item);
                              }}
                            />
                          ) : null}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        {loading === false && donorList.totalNoOfRecords > 10 ? (
          <div className="row">
            <div className="col-md-4 float-start">
              Showing{" "}
              {activePage === 1 ? activePage : (activePage - 1) * pageCount} to{" "}
              {Number(activePage) * pageCount} of {donorList.totalNoOfRecords}{" "}
              items{" "}
            </div>
            <div className="col-md-5 align-me">
              <Pagination
                activePage={activePage}
                itemsCountPerPage={pageCount}
                totalItemsCount={donorList.totalNoOfRecords}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
              />
            </div>
            <div className="col-md-3 float-right" id="select-width">
              <span>
                {" "}
                Show{" "}
                <Form.Select
                  onChange={pageCountChange}
                  id="select-width"
                  value={pageCount}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                </Form.Select>{" "}
                entries
              </span>
            </div>
          </div>
        ) : null}
      </div>
      <Modal show={loading} centered className="modal-con">
        <div className="modal-con">
          <Spinner style={{ textAlign: "center" }} animation="border" />
        </div>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        hideProgressBar
        closeOnClick
        rtl={false}
      />

      <Modal
        show={mapOpen}
        aria-labelledby="contained-modal-title-vcenter"
        className="custom-species-popup"
        centered
      >
        <Modal.Header
          closeButton
          onClick={() => {
            closeModalpop();
          }}
        >
          <h5> Project Mapping </h5>
        </Modal.Header>
        <Modal.Body>
          {/* <h3 className='sub-heading-title' style={{ marginLeft: '10px' }}>
            {' '}
            Donated Project : {proValue.projectName}
          </h3> */}
          <div className="register-plantation">
            <div className="container">
              <div className="row">
                <form>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="fc-wrapper">
                        <label htmlFor="name" className="form-label">
                          Mapping Project
                        </label>
                        <select
                          name="projectName"
                          id="projectName"
                          required
                          className="form-control"
                          onChange={(e) => {
                            selctPr(e);
                          }}
                        >
                          <option value="">Select Project</option>
                          {projectDataList.map((item) => {
                            return (
                              <option
                                value={[item.id, item.availableSpeciesCount]}
                                key={item.id}
                              >
                                {item.name} ({item.availableSpeciesCount})
                              </option>
                            );
                          })}
                        </select>
                        {error === true ? (
                          <div className="donor-mdl">Please select Project</div>
                        ) : null}
                        {disabledValue === true ? (
                          <div className="donor-mdl">
                            Please select Project available count should be
                            greater than donor count
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              closeModalpop();
            }}
            variant="whiteColor"
            style={{ color: "black" }}
          >
            Close
          </Button>
          {/* <Link to='/admin/donorlist' className='btn btn-approve save-width posi-close-top'>
            Submit
          </Link> */}
          <Button
            disabled={disabledValue === true ? true : false}
            onClick={() => {
              submitMap();
            }}
            className="btn btn-approve save-width posi-close-top"
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
