import React from 'react';

import AdminLayout from '../../adminlayout/index';

import SEO from 'components/seo/index';

import Donorstatuspage from 'components/admin/donorstatuspage';

const Donorlist = () => {
  return (
    <>
      <SEO title='Forests By Heartfulness admin' />
      <AdminLayout>
        <div>
          <Donorstatuspage />
        </div>
      </AdminLayout>
    </>
  );
};

export default Donorlist;
